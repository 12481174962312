import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/molecules/SEO";
import Hero from "../components/molecules/Hero";
import RichText from "prismic-reactjs/src/Component";
import GatsbyImage from "gatsby-image";
import Container from "../components/atoms/Container";
import styled from "styled-components";

const Holder = styled.div`
  color: ${props => props.theme.colours.black};
  background-color: ${props => props.theme.colours.grey};
  padding: 4rem 0;
  @media( ${props => props.theme.breakpoints.lg} ) {
    padding: 8rem 0;
  }
`;

const Inner = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }
  .gatsby-image-wrapper {
    margin-bottom: 2rem;
  }
  > div {
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }
`;

function Post({ data } ) {
  const post = data.prismicPost.data;
  return (
    <>
      <SEO title={post.title.text}/>
      <Hero heroText={post.title.text} colour="Purple"/>
      <Holder>
        <Container>
          <Inner>
            <div>
              <RichText render={post.text.raw}/>
            </div>
            <div>
              {post.images && post.images.map( (image, i) =>
                image.image.fluid && <GatsbyImage key={i} alt={image.image.alt} fluid={image.image.fluid}/>
              )}
            </div>
          </Inner>
        </Container>
      </Holder>
    </>
  );
}

export default Post;

export const postQuery = graphql`
    query($id: String!) {
        prismicPost(id: {eq: $id}) {
            data {
                images {
                    image {
                        alt
                        fluid(maxWidth: 2000) {
                            ...GatsbyPrismicImageFluid_noBase64
                        }
                    }
                }
                text {
                    raw
                }
                title {
                    text
                }
            }
        }
    }
`;
